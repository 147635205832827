import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { DataGrid } from '@mui/x-data-grid';
import { Avatar, Box, Paper, Skeleton, Typography } from '@mui/material';
import api, { useGet } from '~/utils/api';
import Results from '~/models/results';
import { ISession } from '~/models/api/policy';
import { Category } from '~/models/api/Category';
import useFunnelContext, { FunnelContext } from '~/context/FunnelContext';
import DateFilter from '~/components/DateFilter';
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Link, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import { getUserAvatar, getUserNameFromId } from '~/helpers/common';
import { User } from '~/models/auth';
import { FilterSet } from '~/models/filter';

const CategoryIndividualTable: React.FC<{
	isTable: boolean;
}> = ({ isTable }) => {
	const [weeks, setWeeks] = useState<number>(1);
	const [defaultFilter, setDefaultFilter] = useState('Today');

	const [currentFilters, setCurrentFilters] = useState<Partial<FilterSet>>();

	const params = useParams();

	const {
		data: category,
		isLoading,
		refetch: reloadData,
	} = useGet<Results<ISession>>(api.analytics.category(params.id ?? ''), {
		params: currentFilters,
	});

	useEffect(() => {
		void reloadData();
	}, [currentFilters]);

	const sessionsRows = category?.results?.map(
		(session, index) =>
			({
				id: session?._id,
				termValue: `£${session?.genesys?.debitTermValue}`,
				policyValue: `£${session?.totalValue}`,
				policyNumber: session?.genesys?.policyNumber,
				startDate: format(
					new Date(session?.genesys?.startDate ?? Date.now()),
					'yyyy/MM/dd'
				),
				endDate: format(new Date(session?.genesys?.endDate ?? Date.now()), 'yyyy/MM/dd'),
				user: session?.user,
			} ?? [])
	);

	return (
		<Styles>
			<FunnelContext.Provider
				value={{
					currentFilters,
					setDefaultFilter,
					setCurrentFilters,
					setWeeks,
				}}
			>
				<Paper className="paper">
					<DateFilter defaultFilter={defaultFilter} />
					<Box className="grid-container">
						<DataGridPro
							disableColumnResize={false}
							className="grid"
							columns={[
								{
									field: 'policyNumber',
									headerName: 'Policy Number',
									minWidth: 160,
								},
								{ field: 'policyValue', headerName: 'Policy Value', minWidth: 170 },
								{ field: 'termValue', headerName: 'Term Value', minWidth: 170 },
								{
									field: 'user',
									headerName: 'User',
									minWidth: 370,
									renderCell: (params: GridRenderCellParams<User>) => {
										return (
											<Link
												to={`/users/${params?.value?._id}`}
												className="user"
											>
												<Avatar
													className="user-avatar"
													src={getUserAvatar(params?.value?._id)}
												>
													{params?.row?.user?.[0]}
													{params?.row?.user?.[1]}
												</Avatar>
												<Typography variant="body1" className="user-email">
													{params?.value?.email}
												</Typography>
											</Link>
										);
									},
								},
								{ field: 'startDate', headerName: 'Start Date', minWidth: 200 },
								{ field: 'endDate', headerName: 'End Date', minWidth: 200 },
							]}
							rows={sessionsRows ?? []}
						/>
					</Box>
				</Paper>
			</FunnelContext.Provider>
		</Styles>
	);
};

const Styles = styled.div`
	.wrapper {
		padding: ${({ theme }) => theme.spacing(1)};
	}
	.css-iempps .wrapper {
		justify-content: flex-end;
		margin-right: ${({ theme }) => theme.spacing(2)};
	}

	.css-q8hpuo-MuiFormControl-root {
		width: 200px;
	}

	.paper {
		min-height: 600px;
		display: flex;
		flex-direction: column;

		${({ theme }) => theme.breakpoints.down('md')} {
			min-height: 360px;
		}
		.grid-container {
			flex: 1;
			flex-direction: column;
			display: flex;
		}
	}

	.user {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: ${({ theme }) => theme.spacing(1)};

		&-email {
			color: ${({ theme }) => theme.palette.text.primary};
		}
	}
`;

export default CategoryIndividualTable;
