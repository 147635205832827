import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { Box, Card, Grid, Skeleton, Typography } from '@mui/material';
import HighlightedTitle from '~/components/HighlightedTitle';
import api, { useGet } from '~/utils/api';
import Results from '~/models/results';
import { getUserFromId } from '~/helpers/common';

import UserSummary from '~/components/UserSummary';
import AdminEvents from '~/components/AdminEvents';
import EditUserForm from '~/components/EditUserForm';
import { User } from '~/models/auth';

const EditUser: React.FC<{}> = () => {
	const location = useLocation();
	const { userParam } = useParams();

	const {
		data: users,
		isLoading,
		refetch: reloadData,
	} = useGet<Results<User>>(api.accounts.admin);

	const userId = location?.pathname?.split('/')?.pop() ?? '';

	const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);

	useEffect(() => {
		let user = getUserFromId(users?.results ?? [], userId);
		setCurrentUser(user);
	}, [users]);

	const navigate = useNavigate();
	const handleUserChange = () => {
		void reloadData();
	};

	const nameHelpers = {
		hasLegacyName: currentUser?.details?.firstName || currentUser?.details?.lastName,
		hasName: currentUser?.firstName || currentUser?.lastName,
	};

	const fullName = nameHelpers.hasName
		? `${currentUser?.firstName} ${currentUser?.lastName}`
		: nameHelpers.hasLegacyName
		? `${currentUser?.details?.firstName} ${currentUser?.details?.lastName}`
		: 'Name not set';
	const [open, setOpen] = useState<boolean>(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleClick = () => {
		setOpen(true);
	};

	return (
		<Styles>
			<Grid container className="grid" spacing={3}>
				<Grid item xs={12}>
					<Box className="title">
						<HighlightedTitle text="Users" />
						{!currentUser ? (
							<Skeleton variant="text" width="120px" sx={{ fontSize: '1rem' }} />
						) : (
							<Typography className="title-user">/ {fullName}</Typography>
						)}
					</Box>
				</Grid>
				<Grid item xs={12} md={5} lg={4} className="grid-item left">
					<UserSummary
						onChange={handleUserChange}
						open={open}
						isLoading={isLoading}
						handleClick={handleClick}
						handleClose={handleClose}
						fullName={fullName}
						user={currentUser}
					/>
					<AdminEvents user={currentUser} />
				</Grid>

				<Grid className="grid-item right" item sm={0} md={7} lg={8}>
					<Card className="grid-item-card-right">
						<EditUserForm
							onChange={handleUserChange}
							handleClose={handleClose}
							user={currentUser}
						/>
					</Card>
				</Grid>
			</Grid>
		</Styles>
	);
};

const Styles = styled.div`
    .form {
        min-height: 100%;
        display: flex;
        flex-direction: column;
        height: 100%;

        &-stack {
            height: 100%;
        }

        &-submit {
            margin-top: auto;
            max-width: fit-content;
        }
    }

    .grid-item.left {

        .profile-card {
            margin-top: 0;
            margin-bottom: ${({ theme }) => theme.spacing(3)};
        }
    }

    .title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: ${({ theme }) => theme.palette.text.primary};
        padding-left: ${({ theme }) => theme.spacing(2)};
	    
	    &-user {
		    color: ${({ theme }) => theme.palette.text.primary};
	    }
    }

    .grid-item-card-right {
        //border: 1px solid ${({ theme }) => theme.palette.primary.light};
        position: relative;
        z-index: 1;
        height: fit-content;
        padding: ${({ theme }) => theme.spacing(4)};
    }
    
    .grid-item.right{
        ${({ theme }) => theme.breakpoints.down('md')}{
            display: none;
        }
    }

    .grid-item-card {
        position: relative;
        min-height: 450px;
        z-index: 1;

       

            .grid-item-card-content-bottom {
                position: relative;
                height: 450px;
                z-index: 2;
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: center;
                margin: auto;

                &-item {
                    border-bottom: 1px solid ${({ theme }) => theme.palette.action.selected};
                }
            }
        }

        .grid-item-card-title {
            padding: ${({ theme }) => theme.spacing(3)};
        }

        .grid-item-card-stack {
            flex-direction: row;
            justify-content: space-between;
            border-bottom: 1px solid ${({ theme }) => theme.palette.action.selected};
            border-top: 1px solid ${({ theme }) => theme.palette.action.selected};
            padding: ${({ theme }) => theme.spacing(3)};
        }
    }

    .previous-events {
        border-bottom: 1px solid ${({ theme }) => theme.palette.action.selected};

        &-menu {
            padding-left: 0;

            &-item {
                border-bottom: 1px solid ${({ theme }) => theme.palette.action.selected};
                padding-left: ${({ theme }) => theme.spacing(3)};
                gap: ${({ theme }) => theme.spacing(1)};

                flex-wrap: wrap;
                justify-content: space-between;

                &-name {
                    flex: 0;
                }

                &-stack {

                }
            }
        }
    }
`;

export default EditUser;
