import React from 'react';
import styled from '@emotion/styled';
import api from '~/utils/api';
import { Button, Stack, Typography, Card } from '@mui/material';
import { useDarkMode } from '~/utils/contexts';
import { Link, useNavigate } from 'react-router-dom';
import Logout from '~/pages/logout';
const NoPermissionsPage: React.FC<{}> = () => {
	const darkmode = useDarkMode();
	const navigate = useNavigate();

	return (
		<Styles>
			<Stack
				justifyContent="center"
				alignItems="center"
				textAlign="center"
				spacing={2}
				sx={{ width: '100vw', height: '100vh' }}
			>
				<img
					width={300}
					alt="logo"
					src={`${api.cdnURL}/assets/AKLogo${!darkmode ? 'Light' : 'Dark'}.svg`}
				/>
				<Card sx={{ p: 4 }}>
					<Typography variant="h2">User not an admin</Typography>
					<Typography>
						You must be an admin to access this page, please login on an admin account.
					</Typography>
					<Button onClick={() => navigate('/logout')} className="">
						Sign in
					</Button>
				</Card>
			</Stack>
		</Styles>
	);
};

const Styles = styled.div``;

export default NoPermissionsPage;
