import { Theme } from '@mui/material/styles';
import { css } from '@emotion/react';
import { FONT_PRIMARY } from '~/theme/typography';
import { pxToRem } from '~/utils/common';
import { grey } from '@mui/material/colors';
import { transparentize } from 'polished';
import { IconButtonTransparent } from '~/theme/components/IconButton';

const TextField = (theme: Theme) => {
	const darkMode = theme.palette.mode === 'dark';

	return {
		MuiTextField: {
			defaultProps: {
				variant: 'outlined',
			},
			variants: [
				{
					props: { variant: 'filled' },
					style: css`
						&.MuiTextField-root {
							margin-top: 35px;

							.MuiFilledInput-root {
								border-radius: 10px;
								background-color: ${!darkMode
									? theme.palette.common.white
									: theme.palette.background.neutral};

								&::before {
									content: none;
								}

								&::after {
									content: none;
								}

								&:focus::after,
								&:active::after {
									content: none;
								}

								&.Mui-error:after {
									content: none;
								}

								&.Mui-error {
									background-color: ${!darkMode
										? theme.palette.error.main
										: transparentize(0.5, theme.palette.error.main)};
								}
							}

							.MuiFormHelperText-root.Mui-error {
								color: ${darkMode
									? theme.palette.error.light
									: theme.palette.error.main};
							}

							.MuiFormLabel-root {
								// top: -30px;
								font-family: ${FONT_PRIMARY};
								font-weight: 700;
								font-size: ${pxToRem(14)};
								color: ${!darkMode
									? theme.palette.common.black
									: theme.palette.text.primary};
								//transform: none;
							}

							.MuiInputBase-root {
								border: 1px solid
									${darkMode
										? transparentize(0.4, theme.palette.common.black)
										: '#d6d6d6'};
								border-bottom-width: 3px;

								&.Mui-error {
									background: ${darkMode
										? theme.palette.error.darker
										: theme.palette.error.lighter};
								}

								&:hover {
									background-color: ${!darkMode
										? grey[200]
										: transparentize(0.65, theme.palette.common.black)};
								}

								&.Mui-focused,
								&:hover.Mui-focused {
									background-color: ${!darkMode
										? grey[100]
										: transparentize(0.8, theme.palette.common.black)};
								}

								&.Mui-focused {
									&:after {
										//content: none;
									}
								}

								.MuiInputBase-input {
									padding-top: 16px;
									padding-bottom: 16px;
									padding-left: 24px;
									padding-right: 24px;
								}
							}

							.MuiInputAdornment-root {
								${IconButtonTransparent(theme)}
							}
						}
					`,
				},
				{
					props: { variant: 'outlined' },
					style: css`
						&.MuiTextField-root {
							min-width: 240px;
						}

						.MuiOutlinedInput-input[value*='/'] ~ fieldset {
							border-color: ${theme.palette.primary.main} !important;
							border-width: 2px;
						}
					`,
				},
			],
		},
	};
};

export default TextField;
