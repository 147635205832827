import { Theme } from '@mui/material/styles';
import { css } from '@emotion/react';
import { FONT_SECONDARY } from '~/theme/typography';
import { IconButtonTransparent } from '~/theme/components/IconButton';
import { lighten } from '@mui/material';

const Card = (theme: Theme) => {
	const darkMode = theme.palette.mode === 'dark';

	return {
		MuiCard: {
			styleOverrides: {
				root: css`
					border: ${darkMode
						? `1px solid ${theme.palette.action.selected}`
						: `1px solid ${theme.palette.primary.light}`};
					box-shadow: none;
					${IconButtonTransparent(theme)};
				`,
			},
		},
	};
};

export default Card;
