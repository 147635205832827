import { css } from '@emotion/react';
import { FONT_SECONDARY } from '~/theme/typography';
import { darken, lighten, Theme } from '@mui/material';
import { transparentize } from 'polished';

const Chip = (theme: Theme) => {
	const darkMode = theme.palette.mode === 'dark';

	return {
		MuiChip: {
			styleOverrides: {
				root: css`
					border-radius: 4px;
					text-transform: uppercase;
					font-family: ${FONT_SECONDARY};
					padding: ${theme.spacing(0.5, 1)};
					box-shadow: none;
					font-size: 14px;

					.MuiChip-deleteIcon {
						color: ${darken(theme.palette.primary.light, 0.3)};

						&:hover {
							color: ${darken(theme.palette.primary.light, 0.4)};
						}
					}

					&:hover,
					&:focus {
						box-shadow: none;
					}
				`,
			},
			defaultProps: {
				variant: 'outlined',
				disableRipple: true,
				color: 'secondary',
			},
			variants: [
				{
					props: { variant: 'contained' },
					style: css`
						color: ${theme.palette.common.white};
						background-color: ${theme.palette.primary.main};
						&:hover,
						&:focus {
							background-color: ${darken(theme.palette.primary.main, 0.3)};
							color: ${theme.palette.primary.lighter};
						}
					`,
				},
				{
					props: { variant: 'filled' },
					style: css`
						color: ${theme.palette.common.white};
						background-color: ${theme.palette.primary.main};
						font-weight: bold;
						&:hover,
						&:focus {
							background-color: ${darken(theme.palette.primary.main, 0.3)};
							color: ${theme.palette.primary.lighter};
						}
					`,
				},
				{
					props: { variant: 'outlined', color: 'primary' },
					style: css`
						color: ${theme.palette.primary.darker};
						border: 2px solid ${theme.palette.primary.light};
					`,
				},
				{
					props: { variant: 'outlined', color: 'secondary' },
					style: css`
						border-radius: 100px;
						background-color: ${theme.palette.mode === 'dark'
							? theme.palette.action.selected
							: theme.palette.primary.light};
						color: ${theme.palette.mode === 'dark'
							? theme.palette.primary.lighter
							: theme.palette.primary.darker};
						border: 1px solid
							${theme.palette.mode === 'dark'
								? theme.palette.action.selected
								: theme.palette.primary.light};
					`,
				},
			],
		},
	};
};

export default Chip;
