import React from 'react';
import styled from '@emotion/styled';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@mui/material';
import api, { useDelete } from '~/utils/api';
import { useSnackbar } from '~/components/Snackbar';
import { useAuth } from '~/hooks/auth';
import { useNavigate } from 'react-router-dom';
import ArmaKarmaSpinner from '~/components/ArmaKarmaSpinner';

const RemoveUserButton: React.FC<{ userId: string; onDelete?: () => void }> = ({
	userId,
	onDelete,
}) => {
	const { mutateAsync: deleteUser, error, isLoading } = useDelete(api.admin.deleteUser(userId));

	const [openSnackbar] = useSnackbar();
	const navigate = useNavigate();

	const { user } = useAuth();
	const action = async () => {
		try {
			await deleteUser();

			if (userId === user?._id) {
				navigate('/logout');
			} else {
				navigate('/users');
			}
			onDelete?.();
			openSnackbar('User deleted successfully', 'success');
		} catch (e) {
			openSnackbar('Failed to delete user', 'error');
		}
	};

	const [open, setOpen] = React.useState(false);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};
	const handleRemoveClick = async () => {
		await action();
	};
	return (
		<Styles>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{'Confirm to delete a users profile.'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Please be careful before deleting a user. Make sure this is the correct user
						before you proceed.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="outlined" onClick={handleClose}>
						Cancel
					</Button>
					<Button
						color="error"
						disabled={isLoading}
						endIcon={isLoading && <ArmaKarmaSpinner size={20} color="green" />}
						onClick={handleRemoveClick}
						autoFocus
					>
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<Button
				onClick={handleClickOpen}
				color="error"
				disabled={isLoading}
				endIcon={isLoading && <ArmaKarmaSpinner size={20} color="green" />}
			>
				Remove User
			</Button>
		</Styles>
	);
};

const Styles = styled.div``;

export default RemoveUserButton;
