import React, { useState } from 'react';
import styled from '@emotion/styled';
import HighlightedTitle from '~/components/HighlightedTitle';
import { Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useLocation, useParams } from 'react-router-dom';
import CategoriesTable from '~/components/Tables/Categories';
import CategoryIndividualTable from '~/components/Tables/CategoryIndividual';
import api, { useGet } from '~/utils/api';
import Results from '~/models/results';
import { ISession } from '~/models/api/policy';
import { Category } from '~/models/api/Category';
import useFunnelContext from '~/context/FunnelContext';

const CategoriesIndividual: React.FC<{}> = () => {
	const location = useLocation();
	const params = useParams();

	const {
		data: categories,
		isLoading,
		refetch: reloadData,
	} = useGet<Results<Category>>(api.analytics.category(params.id!));

	const { data: allCategories } = useGet<Results<Category>>(api.analytics.categories);

	return (
		<Styles>
			<HighlightedTitle
				text={`Categories / ${
					allCategories?.results?.find((c) => c?._id == params.id)?.name
				}`}
			/>

			<CategoryIndividualTable isTable={true} />
		</Styles>
	);
};

const Styles = styled.div`
	.grid {
	}
`;

export default CategoriesIndividual;
