import React from 'react';
import styled from '@emotion/styled';
import {
	Avatar,
	Card,
	ListItemAvatar,
	ListItemText,
	MenuItem,
	MenuList,
	Stack,
	Typography,
} from '@mui/material';
import { format } from 'date-fns';
import { User } from '~/models/auth';
import ListItem from '@mui/material/ListItem';
import { getUserAvatar } from '~/helpers/common';
import List from '@mui/material/List';

const AdminEvents: React.FC<{ user: User | undefined }> = ({ user }) => {
	return (
		<Styles>
			<Card className="grid-item-card previous-events">
				<Typography variant="body1" className="grid-item-card-title">
					Historical admin edits
				</Typography>
				<Stack className="grid-item-card-stack">
					<Typography variant="body1" className="grid-item-card-stack-item">
						Admin
					</Typography>

					<Typography variant="body1">Date</Typography>
				</Stack>
				<List className="previous-events-menu">
					{user?.meta?.admin
						?.map((adminEvent) => (
							<ListItem className="previous-events-menu-item">
								<ListItemAvatar>
									<Avatar src={getUserAvatar(adminEvent.user?._id)} />
								</ListItemAvatar>
								<ListItemText
									primary={`${adminEvent.user?.firstName} ${adminEvent.user?.lastName}`}
									secondary={format(new Date(adminEvent.timestamp), 'PPpp')}
								/>
							</ListItem>
						))
						.reverse()}
				</List>
			</Card>
		</Styles>
	);
};

const Styles = styled.div`
	.grid-item-card {
		max-height: 400px;
		overflow-y: scroll;
	}
`;

export default AdminEvents;
