import { pxToRem } from '~/utils/common';

export function responsiveFontSizes({
	xs,
	sm,
	md,
	lg,
	xl,
}: {
	xs: number;
	sm?: number;
	md?: number;
	lg?: number;
	xl?: number;
}) {
	return {
		fontSize: pxToRem(xs),
		'@media (min-width:600px)': {
			fontSize: pxToRem(sm ?? xs),
		},
		'@media (min-width:960px)': {
			fontSize: pxToRem(md ?? sm ?? xs),
		},
		'@media (min-width:1280px)': {
			fontSize: pxToRem(lg ?? md ?? sm ?? xs),
		},
		'@media (min-width:1680px)': {
			fontSize: pxToRem(xl ?? lg ?? md ?? sm ?? xs),
		},
	};
}

export const FONT_PRIMARY = 'Gilroy, Open sans, Helvetica, Arial, sans-serif';
export const FONT_SECONDARY = 'Gilroy, Open sans, Helvetica, Arial, sans-serif';

const typography = {
	fontFamily: FONT_PRIMARY,
	fontWeightRegular: 400,
	fontWeightMedium: 600,
	fontWeightBold: 700,
	h1: {
		fontWeight: 600,
		lineHeight: 1.5,
		...responsiveFontSizes({ xs: 22, sm: 22, md: 24, lg: 26, xl: 26 }),
	},
	h2: {
		fontWeight: 600,
		lineHeight: 1.5,
		...responsiveFontSizes({ xs: 20, sm: 20, md: 21, lg: 22, xl: 22 }),
	},
	h3: {
		fontWeight: 600,
		lineHeight: 1.5,

		...responsiveFontSizes({ xs: 16, sm: 18, md: 18, lg: 18 }),
	},
	h4: {
		fontWeight: 600,
		lineHeight: 1.5,
		...responsiveFontSizes({ xs: 16, sm: 16, md: 16, lg: 16 }),
	},
	h5: {
		fontWeight: 600,
		lineHeight: 1.5,
		...responsiveFontSizes({ xs: 14, sm: 14, md: 14, lg: 14 }),
	},
	h6: {
		fontWeight: 600,
		lineHeight: 28 / 18,
		...responsiveFontSizes({ xs: 14, sm: 14, md: 14, lg: 14 }),
	},
	subtitle1: {
		fontWeight: 600,
		lineHeight: 1.5,
		fontSize: pxToRem(16),
		fontFamily: FONT_SECONDARY,
	},
	subtitle2: {
		fontWeight: 600,
		lineHeight: 22 / 14,
		fontSize: pxToRem(14),
		fontFamily: FONT_SECONDARY,
	},
	body1: {
		lineHeight: 1.5,
		fontWeight: 400,
		fontSize: pxToRem(16),
		fontFamily: FONT_SECONDARY,
	},
	body2: {
		lineHeight: 22 / 14,
		fontSize: pxToRem(14),
		fontFamily: FONT_SECONDARY,
	},
	caption: {
		lineHeight: 1.5,
		fontSize: pxToRem(12),
		fontFamily: FONT_SECONDARY,
	},
	overline: {
		fontWeight: 700,
		lineHeight: 1.5,
		fontSize: pxToRem(12),
		letterSpacing: 1.1,
		textTransform: 'uppercase',
		fontFamily: FONT_SECONDARY,
	},
	button: {
		fontWeight: 700,
		lineHeight: 24 / 14,
		fontSize: pxToRem(14),
		textTransform: 'capitalize',
	},
} as const;

export default typography;
