import React from 'react';
import styled from '@emotion/styled';
import HighlightedTitle from '~/components/HighlightedTitle';
import { Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useParams } from 'react-router-dom';
import CategoriesTable from '~/components/Tables/Categories';
import RelativityTable from '~/components/Tables/relativity';

const Relativity: React.FC<{}> = () => {
	return (
		<Styles>
			<HighlightedTitle text="Category Product Rates" />

			<RelativityTable isTable={true} defaultFilter="all" />
		</Styles>
	);
};

const Styles = styled.div`
	.grid {
	}
`;

export default Relativity;
