import React from 'react';
import { Field, Form, Formik } from 'formik';
import { Button, Stack } from '@mui/material';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import * as Yup from 'yup';
import ArmaKarmaSpinner from '~/components/ArmaKarmaSpinner';
import RemoveUserButton from '~/components/RemoveUserButton/RemoveUserButton';
import api, { usePut } from '~/utils/api';
import { useSnackbar } from '~/components/Snackbar';
import PhoneInput from '~/components/PhoneInput';
import { User } from '~/models/auth';

const EditUserSchema = Yup.object({
	firstName: Yup.string()
		.required('First name is required')
		.min(2, 'Must have at least 2 characters in your first name')
		.max(32),
	lastName: Yup.string()
		.required('Last name is required')
		.min(2, 'Must have at least 2 characters in your last name')
		.max(32),
	email: Yup.string().email('Please enter a valid email').required('Email is required'),
	phone: Yup.object({
		countryCode: Yup.string(),
		number: Yup.string(),
	}),
});

const EditUserForm: React.FC<{
	user: User | undefined;
	handleClose: () => void;
	onChange: () => void;
}> = ({ user, handleClose, onChange }) => {
	const { mutateAsync: mutateDetailsAsync, isLoading } = usePut<User, Partial<User>>(
		api.accounts.editDetails(user?._id)
	); //CHANGE ENDPOINT TO USERID ACC

	const [openSnackbar] = useSnackbar();

	const action = async (values: Partial<User>) => {
		try {
			if (values?.phone?.number) {
				await mutateDetailsAsync({
					email: values.email,
					firstName: values.firstName,
					lastName: values.lastName,
					phone: values.phone,
					permissions: { admin: values?.admin! },
				});
			} else {
				await mutateDetailsAsync({
					email: values.email,
					firstName: values.firstName,
					lastName: values.lastName,
					permissions: { admin: values?.admin! },
				});
			}
			onChange();
			openSnackbar('Details changed successfully', 'success');
		} catch (e) {
			openSnackbar('Failed to change user details', 'error');
		}
	};

	const userDetails = !user?.firstName && !user?.lastName;

	return (
		<Formik
			initialValues={{
				email: user?.email ?? '',
				firstName: userDetails ? user?.details?.firstName : user?.firstName ?? '',
				lastName: userDetails ? user?.details?.lastName : user?.lastName ?? '',
				phone: user?.phone ?? { countryCode: '', number: '' },
				admin: user?.permissions?.admin ?? false,
			}}
			enableReinitialize
			validationSchema={EditUserSchema}
			onSubmit={async (values: Partial<User>, { setSubmitting }) => {
				await action(values);
				setSubmitting(false);
				handleClose();
			}}
		>
			{({
				values,
				setFieldValue,
				submitForm,
				isSubmitting,
				enableReinitialize,
				dirty,
				resetForm,
			}) => (
				<Form className="form">
					<Stack spacing={2} className="form-stack">
						<Field
							component={TextField}
							type="firstName"
							label="First Name"
							name="firstName"
							variant="outlined"
							InputLabelProps={{ shrink: !!values?.firstName }}
						/>
						<Field
							component={TextField}
							type="lastName"
							label="Last Name"
							name="lastName"
							variant="outlined"
							InputLabelProps={{ shrink: !!values?.lastName }}
						/>
						<PhoneInput
							value={values?.phone ?? { countryCode: '', number: '' }}
							onChange={(v) => setFieldValue('phone', v)}
						/>
						<Field
							style={{ marginBottom: '32px' }}
							component={TextField}
							name="email"
							type="email"
							label="Email"
							variant="outlined"
							InputLabelProps={{ shrink: !!values?.email }}
						/>
						<Field
							type="checkbox"
							component={CheckboxWithLabel}
							name="admin"
							Label={{ label: 'Admin Privileges' }}
						/>

						<Stack direction="row" gap={1}>
							<Button
								className="form-submit"
								variant="contained"
								color="primary"
								disabled={isLoading || !dirty}
								onClick={submitForm}
								type="submit"
							>
								Update user
								{isLoading && <ArmaKarmaSpinner color="green" size={24} />}
							</Button>
							<RemoveUserButton userId={user?._id ?? ''} onDelete={onChange} />
						</Stack>
					</Stack>
				</Form>
			)}
		</Formik>
	);
};

export default EditUserForm;
