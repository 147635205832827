import styled from '@emotion/styled';
import {
	Avatar,
	Box,
	Button,
	Card,
	CardContent,
	Grid,
	Modal,
	Paper,
	Skeleton,
	Typography,
} from '@mui/material';
import EditUserForm from '~/components/EditUserForm';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { getUserAvatar } from '~/helpers/common';
import { User } from '~/models/auth';

const UserSummary: React.FC<{
	user: User | undefined;
	handleClose: () => void;
	handleClick: () => void;
	fullName: string;
	isLoading: boolean;
	open: boolean;
	onChange: () => void;
}> = ({ user, handleClose, handleClick, open, isLoading, onChange, fullName }) => {
	return (
		<Styles>
			<Modal
				className="modal"
				disablePortal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Paper className="modal-paper">
					<IconButton className="modal-paper-close" onClick={handleClose}>
						<CloseIcon />
					</IconButton>
					<EditUserForm onChange={onChange} handleClose={handleClose} user={user} />
				</Paper>
			</Modal>

			<Card className="card profile-card">
				<Box className="card-background" />
				<CardContent className="card-content">
					{isLoading ? (
						<>
							<Skeleton variant="circular" width={100} height={100} />
							<Skeleton variant="text" width={100}></Skeleton>
							<Skeleton variant="text" width={150}></Skeleton>
						</>
					) : (
						<>
							{' '}
							<Avatar src={getUserAvatar(user)} className="card-content-avatar" />
							<Typography variant="body1" className="content-name">
								{fullName}
							</Typography>
							<Typography variant="body2" className="card-content-email">
								{user?.email}
							</Typography>
						</>
					)}

					<Button
						onClick={handleClick}
						className="card-content-button"
						variant="contained"
						color="primary"
					>
						Edit User
					</Button>
				</CardContent>
			</Card>
		</Styles>
	);
};

const Styles = styled.div`
	.card {
		border: 1px solid ${({ theme }) => theme.palette.action.selected};

		position: relative;
		&-background {
			position: absolute;
			z-index: 1;
			width: 100%;
			top: 0;
			left: 0;
			height: 36%;
			background-color: ${({ theme }) => theme.palette.primary.main};
		}

		&-content {
			position: relative;
			height: 450px;
			z-index: 2;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			margin: auto;

			&-button {
				margin-top: ${({ theme }) => theme.spacing(4)};
				${({ theme }) => theme.breakpoints.up('md')} {
					display: none;
				}
			}

			&-avatar {
				width: 104px;
				height: 104px;

				margin-bottom: ${({ theme }) => theme.spacing(1)};
			}

			&-email {
				color: ${({ theme }) => theme.palette.grey[500]};
			}
		}

		margin-top: ${({ theme }) => theme.spacing(4)};
	}
	.modal {
		display: flex;
		justify-content: center;
		align-items: center;

		&-paper {
			position: relative;
			padding: ${({ theme }) => theme.spacing(7)};

			&-close {
				position: absolute;
				top: 0;
				right: 0;
				margin: ${({ theme }) => theme.spacing(2)};
			}
		}
	}
`;

export default UserSummary;
