import React from 'react';
import styled from '@emotion/styled';
import { Button, Stack, Typography } from '@mui/material';
import { useDarkMode } from '~/utils/contexts';
import api from '~/utils/api';

const Component: React.FC<{ resetError: () => void }> = ({ resetError }) => {
	const darkMode = useDarkMode();
	return (
		<Styles>
			<Stack
				justifyContent="center"
				alignItems="center"
				textAlign="center"
				spacing={2}
				sx={{ width: '100vw', height: '100vh' }}
			>
				<img
					width={300}
					alt="logo"
					src={`${api.cdnURL}/assets/AKLogo${!darkMode ? 'Light' : 'Dark'}.svg`}
				/>
				<Typography variant="h2">An error occurred</Typography>
				<Typography>This has been reported to our team, please try again later.</Typography>
				<Button className="errorpage-button" onClick={resetError}>
					Try again
				</Button>
			</Stack>
		</Styles>
	);
};

const Styles = styled.div``;

export default Component;
