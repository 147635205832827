import { css } from '@emotion/react';
import { FONT_SECONDARY } from '~/theme/typography';
import { darken, lighten, Theme } from '@mui/material';
import { transparentize } from 'polished';

const Button = (theme: Theme) => {
	const darkMode = theme.palette.mode === 'dark';

	return {
		MuiButton: {
			styleOverrides: {
				root: css`
					border-radius: 4px;
					font-weight: 700;
					text-transform: uppercase;
					font-family: ${FONT_SECONDARY};
					padding: ${theme.spacing(0.5, 1)};
					box-shadow: none;
					font-size: 16px;

					&:hover,
					&:focus {
						box-shadow: none;
					}
				`,
			},
			defaultProps: {
				variant: 'contained',
				disableRipple: true,
			},
			variants: [
				{
					props: { variant: 'contained', color: 'primary' },
					style: css`
						color: ${theme.palette.common.white};
						background-color: ${theme.palette.primary.main};

						&:hover,
						&:focus {
							background-color: ${darken(theme.palette.primary.main, 0.3)};
							color: ${theme.palette.primary.lighter};
						}
					`,
				},
				{
					props: { variant: 'contained', color: 'secondary' },
					style: css`
						color: ${theme.palette.common.black};
						background-color: ${theme.palette.primary.light};
						box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
							0 2px 2px 0 rgba(0, 0, 0, 0.14);

						font-weight: lighter;

						&:hover,
						&:focus {
							background-color: ${lighten(theme.palette.primary.main, 0.3)};
							color: ${theme.palette.common.white};
						}
					`,
				},
				{
					props: { variant: 'contained', color: 'tertiary' },
					style: css`
						color: ${theme.palette.tertiary.main};
						background-color: ${theme.palette.tertiary.light};

						&:hover,
						&:focus {
							background-color: ${darken(theme.palette.tertiary.light, 0.1)};
							color: ${theme.palette.tertiary.main};
						}
					`,
				},
				{
					props: { variant: 'outlined', color: 'primary' },
					style: css`
						color: ${theme.palette.primary.main};
						border: 2px solid ${theme.palette.action.selected};

						&:hover,
						&:focus {
							border: 2px solid ${theme.palette.action.selectedOpacity};
							color: ${theme.palette.primary.main};
						}
					`,
				},
				{
					props: { variant: 'outlined', color: 'secondary' },
					style: css`
						color: ${theme.palette.secondary.main};
						border: 2px solid ${theme.palette.secondary.light};

						&:hover,
						&:focus {
							border: 2px solid ${darken(theme.palette.secondary.light, 0.1)};
							color: ${theme.palette.secondary.main};
						}
					`,
				},
				{
					props: { variant: 'outlined', color: 'tertiary' },
					style: css`
						color: ${theme.palette.tertiary.main};
						border: 2px solid ${theme.palette.tertiary.light};

						&:hover,
						&:focus {
							border: 2px solid ${darken(theme.palette.tertiary.light, 0.1)};
							color: ${theme.palette.tertiary.main};
						}
					`,
				},
			],
		},
	};
};

export default Button;
