import { csvButton } from '~/utils/csv';
import { Box, Button, IconButton, Paper, Skeleton, Stack, Typography } from '@mui/material';
import React, { ReactNode, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { DataGrid } from '@mui/x-data-grid';
import api, { useGet, usePut } from '~/utils/api';
import Results from '~/models/results';
import { ISession } from '~/models/api/policy';
import { Category } from '~/models/api/Category';
import useFunnelContext, { FunnelContext } from '~/context/FunnelContext';
import DateFilter from '~/components/DateFilter';
import { DataGridPro, GridRenderCellParams } from '@mui/x-data-grid-pro';
import { Link, useNavigate } from 'react-router-dom';
import { User } from '~/models/auth';
import { IDeviceCategory } from '~/models/api/deviceCategory';
import UpdateRelativityModal from '../UpdateRelativityModal';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { IPricingCategory } from '~/models/api/pricingCategory';

const RelativityTable: React.FC<{
	currentFilters?: {};
	isTable: boolean;
	defaultFilter: string;
	setDefaultFilter?: () => void;
}> = ({ setDefaultFilter, currentFilters: filters, defaultFilter, isTable }) => {
	const [weeks, setWeeks] = useState<number>(1);

	const [currentFilters, setCurrentFilters] = useState(filters);
	const {
		data: categories,
		isLoading,
		refetch: reloadData,
	} = useGet<Results<IPricingCategory>>(api.pricingCategory.all, {
		params: currentFilters,
	});

	useEffect(() => {
		void reloadData();
	}, [currentFilters]);

	const tableCategories = categories?.results?.map(
		(category, index) =>
			({
				id: category?._id,
				name: category?.name ?? 'Name Not Set',
				productRate: category?.productRate ?? 0,
			} ?? [])
	);
	const [open, setOpen] = useState(false);

	const [categoryId, setCategoryId] = useState('');

	const [productRate, setProductRate] = useState(0);

	const navigate = useNavigate();
	return (
		<Styles>
			<FunnelContext.Provider
				value={{
					currentFilters,
					setCurrentFilters,
					setDefaultFilter,
					setWeeks,
				}}
			>
				<UpdateRelativityModal
					productRate={productRate}
					refreshTable={reloadData}
					categoryId={categoryId}
					open={open}
					handleClose={setOpen}
				/>
				<Stack mb={2} justifyContent="end" alignItems="end">
					<Box>
						<Button onClick={() => navigate('/categories')}>Back</Button>
					</Box>
				</Stack>
				<Paper className="paper">
					<Box className="grid-container">
						<DataGridPro
							className="grid"
							disableColumnResize={false}
							columns={[
								{
									field: 'name',
									headerName: 'Item name',
									flex: 1,
									renderCell: (params: GridRenderCellParams<User>) => {
										return (
											// <Link
											// 	to={`/categories/${params?.id}`}
											// 	className="category"
											// >
											<Typography
												variant="body1"
												color="black"
												className="category-name"
											>
												{params?.value as ReactNode}
											</Typography>
										);
									},
								},
								{
									field: 'productRate',
									headerName: 'Product Rate',
									flex: 1,
									renderCell: (params: GridRenderCellParams<User>) => {
										return (
											<Typography
												variant="body1"
												color="black"
												className="category-name"
											>
												{params?.row?.productRate as ReactNode}
											</Typography>
										);
									},
								},
								{
									field: 'edit',
									headerName: 'Actions',
									flex: 1,
									renderCell: (params: GridRenderCellParams<User>) => {
										return (
											<Stack direction="row" spacing={2}>
												<IconButton
													onClick={() => {
														setProductRate(params?.row?.productRate);
														setCategoryId(params?.row?.id);
														setOpen((prev) => !prev);
													}}
												>
													<EditIcon />
												</IconButton>
											</Stack>
										);
									},
								},
							]}
							rows={tableCategories ?? []}
						/>
					</Box>
				</Paper>
			</FunnelContext.Provider>
		</Styles>
	);
};

const Styles = styled.div`
	.wrapper {
		padding: ${({ theme }) => theme.spacing(1)};
	}
	.category {
		display: flex;
		gap: ${({ theme }) => theme.spacing(1)};
		align-items: center;
	}

	.category-name {
		color: ${({ theme }) =>
			theme.palette.mode === 'dark'
				? theme.palette.common.white
				: theme.palette.primary.darker};
	}
	.stack {
		flex-direction: row;
		gap: ${({ theme }) => theme.spacing(1)};
		align-items: flex-end;
		justify-content: flex-end;
	}
	.css-iempps .wrapper {
		justify-content: flex-end;
		margin-right: ${({ theme }) => theme.spacing(2)};
	}
	.css-q8hpuo-MuiFormControl-root {
		width: 200px;
	}
	.paper {
		min-height: 600px;
		display: flex;
		flex-direction: column;
		${({ theme }) => theme.breakpoints.down('md')} {
			min-height: 360px;
		}

		.grid-container {
			flex: 1;
			flex-direction: column;
			display: flex;
		}
	}

	.category-name {
		color: ${({ theme }) => theme.palette.text.primary};
	}
`;

export default RelativityTable;
