import React, { Dispatch, SetStateAction } from 'react';
import styled from '@emotion/styled';
import { Field, Form, Formik, FormikValues } from 'formik';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Stack,
} from '@mui/material';
import { CheckboxWithLabel, TextField } from 'formik-mui';
import ArmaKarmaSpinner from '~/components/ArmaKarmaSpinner';
import * as Yup from 'yup';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import api, { usePost, usePut } from '~/utils/api';
import { useSnackbar } from '~/components/Snackbar';
import { EditRelativitySchema, NewUserSchema } from '~/helpers/ValidationSchemas';
import { User } from '~/models/auth';
import { IDeviceCategory } from '~/models/api/deviceCategory';
import { IPricingCategory } from '~/models/api/pricingCategory';

const UpdateRelativityModal: React.FC<{
	handleClose: Dispatch<SetStateAction<boolean>>;
	refreshTable: () => void;
	productRate: number;
	categoryId: string;
	open: boolean;
}> = ({ handleClose, open, categoryId, refreshTable, productRate }) => {
	const schema = Yup.object(EditRelativitySchema);

	const {
		mutateAsync: editPricingCategory,
		isSuccess,
		isError,
	} = usePut<IPricingCategory, { productRate: number }>(api.pricingCategory.edit(categoryId));

	const [openSnackbar] = useSnackbar();

	const action = async (values: FormikValues) => {
		try {
			await editPricingCategory({
				productRate: Number(values?.productRate),
			});
			refreshTable();
			openSnackbar('Item rate successfully updated', 'success');
		} catch (e) {
			openSnackbar('Failed to edit item rate', 'error');
		}
	};

	return (
		<Styles>
			<Dialog
				className="modal"
				disablePortal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Stack direction="row" flexGrow={1}>
					<Box component="span">
						<DialogTitle variant="h2">Edit Product Rate</DialogTitle>
					</Box>
					<Box>
						<IconButton
							className="modal-paper-close"
							onClick={() => handleClose(false)}
						>
							<CloseIcon />
						</IconButton>
					</Box>
				</Stack>
				<Formik
					initialValues={{ productRate }}
					enableReinitialize
					validationSchema={schema}
					onSubmit={async (values, { setSubmitting }) => {
						await action(values);
						handleClose(false);
					}}
				>
					{({ isSubmitting, handleSubmit, enableReinitialize, values }) => (
						<Form className="newuser-form">
							<DialogContent>
								<Stack className="newuser-form-stack" spacing={2}>
									<Field
										component={TextField}
										name="productRate"
										label="Edit Item Product Rate"
										fullWidth
										margin="none"
										autoFocus
										InputLabelProps={{
											className: 'label',
										}}
									/>
									<DialogActions>
										<Button
											className="form-submit"
											variant="contained"
											color="primary"
											disabled={isSubmitting}
											onClick={() => handleSubmit()}
										>
											Edit
											{isSubmitting && (
												<ArmaKarmaSpinner color="green" size={24} />
											)}
										</Button>
									</DialogActions>
								</Stack>
							</DialogContent>
						</Form>
					)}
				</Formik>
			</Dialog>
		</Styles>
	);
};

const Styles = styled.div`
	.MuiFormControl-root {
		width: 100%;
	}

	.form-submit {
		width: fit-content;
		margin-left: auto;
	}

	.newuser-form {
		margin-top: ${({ theme }) => theme.spacing(2)};
		min-width: 690px;
		${({ theme }) => theme.breakpoints.down('lg')} {
			min-width: 450px;
		}
		${({ theme }) => theme.breakpoints.down('md')} {
			min-width: 300px;
		}

		&-stack {
			justify-content: center;
		}
	}

	.modal {
		display: flex;
		justify-content: center;
		align-items: center;
		&-paper {
			position: relative;
			padding: ${({ theme }) => theme.spacing(7)};
			min-width: 50%;
			min-height: fit-content;
			${({ theme }) => theme.breakpoints.up('lg')} {
				min-width: 30%;
			}
			&-close {
				position: absolute;
				top: 0;
				right: 0;
				margin: ${({ theme }) => theme.spacing(2)};
			}
		}
	}
`;

export default UpdateRelativityModal;
