import React from 'react';
import { Button, Typography } from '@mui/material';
import { FallbackRender } from '@sentry/react';
import Error from '~/components/Error';

export const ErrorBoundary: FallbackRender = ({ error, resetError }) => {
	const status = (error as any)?.response?.status;
	const data = (error as any)?.response?.data;
	const errorName = (error as any)?.response?.data?.errorName || (error as any)?.name;

	if (status === 500) {
		return <p>You're offline!</p>;
	}

	if (status === 404) {
		return (
			<>
				<img
					width={200}
					className="login-logo"
					alt="logo"
					src={'https://arma-karma.s3.eu-west-2.amazonaws.com/assets/AKlogoblack.png'}
				/>
				<Typography variant="h1">404</Typography>
				<Typography>Not found!</Typography>
				<Button onClick={() => (window.location.href = '/')}>Go home</Button>
			</>
		);
	}

	// the response json is automatically parsed to
	// `error.data`, you also have access to the status
	return (
		<div>
			<Error resetError={resetError} />
		</div>
	);
};

export default ErrorBoundary;
